import React, { FC, MouseEvent } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import Gravatar from 'react-gravatar'
import Tippy from '@tippyjs/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle, faSignOutAlt } from '@fortawesome/pro-solid-svg-icons'

import { User } from '../../interfaces/auth'
import useUser from '../../utils/hooks/useUser'
import fetchJson from '../../utils/fetchJson'
import Logo from '../Logo'

interface ProfilePopoverProps {
  user: User
  onLogout: (event: MouseEvent<HTMLDivElement>) => void
}

const ProfilePopover: FC<ProfilePopoverProps> = ({ user, onLogout }) => (
  <div style={{ minWidth: 180 }}>
    <div className="px-3 py-2 border-b">
      <div className="font-medium text-xs mb-1">{user.email}</div>
      <div className="text-xs">{[user.firstName, user.lastName].join(' ')}</div>
    </div>
    <div className="px-3 py-2">
      <Link href="/profile">
        <a className="flex items-center text-xs text-primary mb-1">
          <FontAwesomeIcon icon={faUserCircle} className="mr-1" />
          Account
        </a>
      </Link>
      <div className="text-xs text-red-600 cursor-pointer" onClick={onLogout}>
        <FontAwesomeIcon icon={faSignOutAlt} className="mr-1" />
        Log out
      </div>
    </div>
  </div>
)

const SideNav: FC = () => {
  const { user, mutateUser } = useUser()
  const router = useRouter()

  const onLogout = async (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    await mutateUser(fetchJson('/api/auth/logout', { method: 'POST' }))
    router.push('/login')
  }

  return (
    <aside className="flex flex-col items-center bg-primary-darkest py-4 w-16">
      <Link href="/">
        <a className="flex">
          <Logo />
        </a>
      </Link>
      <div className="mt-auto">
        {user?.isLoggedIn && (
          <Tippy
            theme="light"
            interactive
            content={<ProfilePopover user={user} onLogout={onLogout} />}
            trigger="click"
            placement="right-end"
          >
            <div className="w-10 h-10 rounded-full bg-gray-200 overflow-hidden cursor-pointer">
              <Gravatar email={user.email} size={40} default="mm" />
            </div>
          </Tippy>
        )}
      </div>
    </aside>
  )
}

export default SideNav
