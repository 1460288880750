import React, { FC } from 'react'
import Head from 'next/head'
import { LogOnMount } from 'react-amplitude-hooks'

import SideNav from '../SideNav'

interface LayoutProps {
  pageName?: string
  withSideNav?: boolean
}

const Layout: FC<LayoutProps> = ({
  children,
  pageName,
  withSideNav = true,
}) => (
  <div className="flex flex-row min-h-screen">
    {pageName && (
      <LogOnMount eventType="Page View" eventProperties={{ name: pageName }} />
    )}
    <Head>
      <title>userstoriz</title>
      <meta charSet="utf-8" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <link rel="shortcut icon" href="/favicon.ico" />
    </Head>
    {withSideNav && <SideNav />}
    <main className="flex flex-col flex-1 max-h-screen overflow-y-auto">
      <div className="flex items-center justify-center flex-shrink-0 h-10 bg-teal-100">
        <span className="text-xs text-teal-500">
          You’re currently using a beta version of userstoriz. Please send us
          any feedback, bugs or request to{' '}
          <a href="mailto:beta@userstoriz.com">beta@userstoriz.com</a> ❤️
        </span>
      </div>
      {children}
    </main>
  </div>
)

export default Layout
