import React, { SVGProps } from 'react'

const Logo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="24"
    fill="none"
    viewBox="0 0 20 24"
    {...props}
  >
    <g fill="#FDFDFF" clipPath="url(#clip0_logo)">
      <path
        fillRule="evenodd"
        d="M18.285 22.405V4.71h1.476V24H3.221C1.443 24 0 22.525 0 20.706v-4.68l6.428-4.328a4.13 4.13 0 004.177-.263 4.132 4.132 0 001.827-3.58.295.295 0 00-.165-.258.293.293 0 00-.305.018l-1.946 1.31-1.467-1.113-.48-1.778 1.947-1.31a.305.305 0 00.13-.277.3.3 0 00-.178-.25 4.13 4.13 0 00-4.002.347 4.13 4.13 0 00-1.816 3.77L0 11.11V3.294C0 1.475 1.443 0 3.222 0h12.39l.19.202v18.113H3.232c-1.037 0-1.877.84-1.877 1.878v.334c0 1.037.84 1.878 1.877 1.878h15.053z"
        clipRule="evenodd"
      ></path>
      <path d="M2.697 20.395c0 .52.358.942.798.942h12.38v-1.883H3.496c-.44 0-.798.422-.798.941z"></path>
    </g>
    <defs>
      <clipPath id="clip0_logo">
        <path fill="#fff" d="M0 0H19.761V24H0z"></path>
      </clipPath>
    </defs>
  </svg>
)

export default Logo
